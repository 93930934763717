// src/components/EventIcon.js
import React from 'react';
import { FaVideo, FaImage, FaAward, FaCross, FaHospitalAlt, FaSchool } from 'react-icons/fa';
import styled from 'styled-components';

// Componente para ícones personalizados com base no tipo de evento
const EventIcon = ({ eventType }) => {
  switch (eventType) {
    case 'birth':
      return <StyledIcon as={FaAward} color="#4caf50" />; // Ícone para nascimento
    case 'religious':
      return <StyledIcon as={FaCross} color="#9c27b0" />; // Ícone para eventos religiosos
    case 'edu':
      return <StyledIcon as={FaSchool} color="#9c27b0" />; // Ícone para eventos Educacional
    case 'hospital':
      return <StyledIcon as={FaHospitalAlt} color="#ff9800" />; // Ícone para hospital
    case 'award':
      return <StyledIcon as={FaAward} color="#ff5722" />; // Ícone para prêmios
    case 'video':
      return <StyledIcon as={FaVideo} color="#f44336" />; // Ícone para vídeos
    case 'image':
    default:
      return <StyledIcon as={FaImage} color="#2196f3" />; // Ícone padrão para imagens
  }
};

export default EventIcon;

const StyledIcon = styled.div`
  font-size: 20px;
  color: ${({ color }) => color || '#000'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
